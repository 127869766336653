define("discourse/plugins/swapd-fees/discourse/templates/components/fc-section", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="fc-section-help">
    <div class="fc-section-num">
      <span>{{this.num}}</span>
    </div>
  
    <div class="fc-section-msg">
      {{html-safe this.msg}}
    </div>
  </div>
  
  <div class="fc-section-input">
    {{yield}}
  </div>
  
  */
  {
    "id": "Ob+oDLBZ",
    "block": "[[[10,0],[14,0,\"fc-section-help\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"fc-section-num\"],[12],[1,\"\\n    \"],[10,1],[12],[1,[30,0,[\"num\"]]],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"fc-section-msg\"],[12],[1,\"\\n    \"],[1,[28,[35,2],[[30,0,[\"msg\"]]],null]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"fc-section-input\"],[12],[1,\"\\n  \"],[18,1,null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&default\"],false,[\"div\",\"span\",\"html-safe\",\"yield\"]]",
    "moduleName": "discourse/plugins/swapd-fees/discourse/templates/components/fc-section.hbs",
    "isStrictMode": false
  });
});